import React, {
  useState,
  useEffect,
  useCallback,
} from "react";
import { useDispatch, connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Label,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { fetchGatewaysStart } from "store/gateway/action";
import { addDepositStart, addFormDataDeposit } from "store/transactions/deposit/action";
import { fetchWalletStart, clearWallets } from "store/wallet/list/action";
import { fetchClientsStart } from "store/client/actions";
import "./SearchableInputStyles.scss";
import { withTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import Loader from "components/Common/Loader";
import * as clientApi from "apis/client";
import { debounce } from "lodash";
import { addNewMerchants } from "apis/merchants";

function AddMerchantModal(props){
  const [addModal, setDepositModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedWalletId, setSelectedWalletId] = useState("");
  const [gateway, setGateway] = useState("");
  const [type, setType] = useState("LIVE");
  const dispatch = useDispatch();
  const { create } = props.depositsPermissions;
  const [searchInput, setSearchInput]  = useState("");
  const [file, setFile] = useState({});

  const handleAddDeposit = async (event, values) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("customerId", selectedClient);
    console.log(formData, selectedClient);
    await addNewMerchants(formData);
    toggleAddModal();
  };
  
  const toggleAddModal = () => {
    setDepositModal(!addModal);
  };
  useEffect(()=>{
    dispatch(fetchClientsStart({
      page:1,
      type
    }));
    dispatch(fetchGatewaysStart());
    if (searchInput.length >= 3){
      dispatch(fetchClientsStart({
        searchText:searchInput,
        type
      }));
    }
  
  }, [searchInput, type]);

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, cb) => {
      clientApi.getClients({
        payload: {
          searchText: inputValue,
          type: "LIVE"
        }
      }).then((data) => {
        return cb(data?.result?.docs.map((item) => (
          {
            label : `${item.firstName} ${item.lastName}`,
            value : {
              name: `${item.firstName} ${item.lastName}`,
              id: `${item._id}`
            }
          }
        )));
      });
    }, 1000), []
  );


  useEffect(() => {
    setGateway("");
  }, [open, addModal]);
  
  useEffect(() => {
    if (!props.disableAddButton && open ){
      setDepositModal(false);
    }
  }, [props.modalClear]);
  
  const selectClient = (id)=>{
    setSelectedClient(id);
    dispatch(fetchWalletStart({
      belongsTo:id,
      customerId:id,
    }));
  };

  return (
    <React.Fragment >
      <Link to="#" className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i> {props.t("Add Merchant")}</Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Add Merchant")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='px-4 py-2'
            onValidSubmit={(e, v) => {
              delete v.client;
              delete v.clientId;
              delete v.wallet;
              delete v.gateway;
              handleAddDeposit(e, v);
            }}
          >
            <Row className="mb-3">
              <Col md="12">                      
                <Label>{props.t("Client")}</Label>
                <div>
                  <AsyncSelect
                    isClearable
                    placeholder = {props.t("Choose A Client")}
                    defaultOptions={props.loading ? [] : props.clients.map((item) => (
                      {
                        label : `${item.firstName} ${item.lastName}`,
                        value : {
                          name: `${item.firstName} ${item.lastName}`,
                          id: `${item._id}`
                        }
                      }
                    ))}
                    classNamePrefix="select2-selection"
                    loadOptions={debouncedChangeHandler}
                    onChange={(e) => { 
                      if (e && e.value && e.value.id) {
                        selectClient(e.value.id);
                      }
                    }}
                    isRequired={true}
                    isSearchable={true}
                    backspaceRemoves={true}
                    name="clientId"
                  />
                  {/* <Select 
                    onChange={(e) => { 
                      selectClient(e.value.id);
                    }}
                    isSearchable = {true}
                    options={props.loading ? [] : props.clients.map((item) => (
                      {
                        label : `${item.firstName} ${item.lastName}`,
                        value : {
                          name: `${item.firstName} ${item.lastName}`,
                          id: `${item._id}`
                        }
                      }
                    ))}
                    classNamePrefix="select2-selection"
                    placeholder = {props.t("Choose A Client")}
                    name = "clientId"
                    isRequired = {true}
                    isLoading={props.loading}
                  /> */}
                  <AvField
                    name="clientId"
                    type="text"
                    errorMessage={props.t("Choose A Client")}
                    validate={{ required: { value: true } }}
                    value={selectedClient}
                    style={{
                      opacity: 0,
                      height: 0,
                      width: 0,
                      margin: -10
                    }}
                  />
                </div>
              </Col>
            </Row>
          
            <div className='text-center mt-3 p-2'>
              {
                props.addLoading 
                  ?
                  <Loader />
                  :
                  <Button 
                    disabled = {props.addLoading} 
                    type="submit" 
                    color="primary"
                  >
                    {props.t("Add")}
                  </Button>
              }
            </div>
          </AvForm>
          {props.error && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.t(props.error)}
          </UncontrolledAlert>}
        </ModalBody> 
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  gateways:state.gatewayReducer.gateways || [],
  modalClear:state.depositReducer.modalClear,
  depositResponseMessage:state.depositReducer.depositResponseMessage,
  clients:state.clientReducer.clients || [],
  wallets:state.walletReducer.wallet.wallets || [],
  depositsPermissions : state.Profile.depositsPermissions || {}, 
  disableAddButton : state.depositReducer.disableAddButton,
  addLoading: state.depositReducer.addLoading
});
export default connect(mapStateToProps, null)(withTranslation()(AddMerchantModal));

export const validateFile = (allowedExtensions = [], size, file, messages) => (value, ctx, input, cb) => {
  const {
    sizeValidationMessage = "The file size is too large",
    extensionValidationMessage = "The file extension is not allowed",
  } = messages || {};
  const extension = value?.split(".")?.pop()?.toLowerCase();
  if (allowedExtensions.includes(extension) || !value){
    if (!value || file?.size <= size){
      cb(true);
    } else cb(sizeValidationMessage);
  } else cb(extensionValidationMessage);
};