// utils/activityTracker.js

const ACTIVITY_TIMEOUT = 3 * 60 * 1000; // 3 minutes in milliseconds
// const ACTIVITY_TIMEOUT = 30 * 1000; // 3 minutes in milliseconds

export function initializeActivityTracker() {
  function updateActivity() {
    console.log("lastActivity");
    localStorage.setItem("lastActivity", Date.now().toString());
  }

  // Track mouse movements, clicks, and key presses
  document.addEventListener("mousemove", updateActivity);
  document.addEventListener("mousedown", updateActivity);
  document.addEventListener("keypress", updateActivity);

  // Set a timer to check for inactivity
  const checkInactivity = () => {
    const lastActivity = localStorage.getItem("lastActivity");
    console.log("checkInactivity", lastActivity);
    if (lastActivity && (Date.now() - parseInt(lastActivity, 10)) > ACTIVITY_TIMEOUT) {
      // Perform logout action
      logoutUser();
    }
  };

  setInterval(checkInactivity, 1000); // Check every second
}

function logoutUser() {
  console.log("logout");
  // Broadcast logout event to other tabs
  localStorage.setItem("logout", Date.now().toString());
  window.location.href = "/login";
}