import qs from "qs";
import * as axiosHelper from "./api_helper";

export const getMerchants = async () => {
  const data = await axiosHelper.get("/merchants");
  return data;
};

export const addNewMerchants = async (data = {}) => {
  console.log("data", data);
  const result = await axiosHelper.post("/merchants", data);
  return result;
};

export const fetchMasterWallet = async () => {
  const data = await axiosHelper.get("/Merchants-management/master-wallet");
  return data;
};

export const fetchMerchantsBalance = async (Merchants) => {
  const data = await axiosHelper.get(`/Merchants-management/balance/${Merchants}`);
  return data;
};

export const withdrawFromMerchants = async (data) => {
  const result = await axiosHelper.post("/Merchants-management/withdraw", data);
  return result;
};