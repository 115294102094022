import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import {
  Modal, Button, ModalHeader, ModalBody, UncontrolledAlert, 
} from "reactstrap";
import {
  AvForm, AvField,
} from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import { editUserPass } from "store/users/actions";
import Icofont from "react-icofont";
import { getUserProfile } from "store/actions";

function ChangePasswordModal(props) {
  const { show, t, userData, editSuccess, editError, editResult } = props;
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(show);

  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    cnfPassword: ""
  });

  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    cnfPassword: false
  });

  const handleShowPassword = (field) => {
    setShowPassword(prevState => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleEditPassword = (e, values) => {
    dispatch(editUserPass({
      id: userData._id,
      values
    }));
  };

  useEffect(() => {
    if (editSuccess) {
      setModalOpen(false);
      dispatch(getUserProfile());
    }
  }, [editSuccess]);

  useEffect(() => {
    setModalOpen(show);
  }, [show]);

  return (
    <React.Fragment>
      <Modal isOpen={modalOpen} centered={true}>
        <ModalHeader tag="h4">
          <div className="d-flex justify-content-between align-items-center w-100">
            {t("Change Password")}
            <Button color="transparent" onClick={(e)=> setModalOpen(false)}>X</Button>
          </div>
        </ModalHeader>
        <ModalBody>
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              handleEditPassword(e, v);
            }}
          >
            <div className="mb-3 position-relative">
              <AvField
                name="oldPassword"
                label="Current Password"
                placeholder="Current Password"
                type={showPassword.oldPassword ? "text" : "password"}
                value={passwordData.oldPassword}
                onChange={handleInputChange}
                errorMessage="Enter Current Password"
                validate={{ required: { value: true } }}
              />
              <Icofont
                icon={showPassword.oldPassword ? "eye-blocked" : "eye"}
                className="show-password position-absolute"
                onClick={() => handleShowPassword("oldPassword")}
                style={{
                  fontSize: "25px",  
                  cursor: "pointer",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-140%)"
                }}
              />
            </div>
            <div className="mb-3 position-relative">
              <AvField
                name="newPassword"
                label="New Password"
                placeholder="Password"
                type={showPassword.newPassword ? "text" : "password"}
                value={passwordData.newPassword}
                onChange={handleInputChange}
                errorMessage="Enter New password"
                validate={{ required: { value: true } }}
              />
              <Icofont
                icon={showPassword.newPassword ? "eye-blocked" : "eye"}
                className="show-password position-absolute"
                onClick={() => handleShowPassword("newPassword")}
                style={{
                  fontSize: "25px",  
                  cursor: "pointer",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-140%)"
                }}
              />
            </div>
            <div className="mb-3 position-relative">
              <AvField
                name="cnfPassword"
                label="Confirm Password"
                placeholder="Re-type Password"
                type={showPassword.cnfPassword ? "text" : "password"}
                value={passwordData.cnfPassword}
                onChange={handleInputChange}
                errorMessage="Password Not Match"
                validate={{
                  required: { value: true },
                  match: { value: "newPassword" },
                }}
              />
              <Icofont
                icon={showPassword.cnfPassword ? "eye-blocked" : "eye"}
                className="show-password position-absolute"
                onClick={() => handleShowPassword("cnfPassword")}
                style={{
                  fontSize: "25px",  
                  cursor: "pointer",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-140%)"
                }}
              />
            </div>
            <div className='text-center p-5'>
              <Button type="submit" color="primary">
                Update Password
              </Button>
            </div>
          </AvForm>
          {editError && (
            <UncontrolledAlert color="danger">
              <i className="mdi mdi-block-helper me-2"></i>
              {editError}
            </UncontrolledAlert>
          )}
          {editResult && (
            <UncontrolledAlert color="success">
              <i className="mdi mdi-check-all me-2"></i>
              Update Password successfully !!!
            </UncontrolledAlert>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  addLoading: state.usersReducer.addLoading,
  editResult: state.usersReducer.editResult,
  editError: state.usersReducer.editError,
  editSuccess: state.usersReducer.editSuccess,
  editClearingCounter: state.usersReducer.editClearingCounter,
});

export default connect(mapStateToProps, null)(withTranslation()(ChangePasswordModal));
