import React, { useEffect, useState } from "react";
import {
  useDispatch, useSelector
} from "react-redux";

import {
  Row, Col, Card, CardBody, CardTitle, CardHeader, Input, Label, Spinner,
} from "reactstrap";

import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";

import TableLoader from "components/Common/TableLoader";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import { MetaTags } from "react-meta-tags";
import formatDate from "helpers/formatDate";
import { fetchAccountTypes, updateAccountType } from "store/actions";
import { Link } from "react-router-dom";
import useModal from "hooks/useModal";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import Sidebar from "components/PayFiniticDashboardlLayout/Sidebar";


function PayFiniticDashboard(props) {
  const [accountType, setAccountType] = useState();
  const [walletOption, setWalletOption] = useState("");
  const [durationOption, setDurationOption] = useState("");
  const [timeOption, setTimeOption] = useState("");
  const [amountOption, setAmountOption] = useState("");
  const [editModal, toggleEditModal] = useModal();

  const {
    userData,
    loading,
    docs,
    page,
    totalDocs,
    totalPages,
    hasNextPage,
    hasPrevPage,
    limit,
    nextPage,
    pagingCounter,
    prevPage,
    deleteLoading,
    deleteClearingCounter,
    // roles,
    // editClearingCounter,
    teamsPermissions
  } = useSelector((state) => ({
    userData: state.Profile.userData,
    loading: state.walletReducer.wallet.loading || false,
    docs: state || [],
    page: state.walletReducer.wallet.page || 1,
    totalDocs: state.walletReducer.wallet.totalDocs || 0,
    totalPages: state.walletReducer.wallet.totalPages || 0,
    hasNextPage: state.walletReducer.wallet.hasNextPage,
    hasPrevPage: state.walletReducer.wallet.hasPrevPage,
    limit: state.walletReducer.wallet.limit,
    nextPage: state.walletReducer.wallet.nextPage,
    pagingCounter: state.walletReducer.wallet.pagingCounter,
    prevPage: state.walletReducer.wallet.prevPage,
    deleteLoading: state.walletReducer.wallet.deleteLoading,
    deleteClearingCounter: state.walletReducer.wallet.deleteClearingCounter,
    // roles: state.walletReducer.wallet.rolesData,
    clearingCounter: state.walletReducer.wallet.clearingCounter,
    // editClearingCounter: state.walletReducer.wallet.editClearingCounter,
    teamsPermissions: state.Profile.teamsPermissions || {}
  }));
  const cryptoWalletsColumns = [
    {
      dataField: "wallets",
      text: "Crypto Wallets",
      formatter: (val) => formatDate(val.createdAt)
    },
    {
      dataField: "balance",
      text: "Balance",
      formatter: (item) => (
        captilazeFirstLetter(item.title)
      )
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Actions",
      formatter: (item) => (
        <Link className="New Payment" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => { setAccountType(item); toggleEditModal() }}
          ></i>
        </Link>
      )
    },

  ];
  const fiatWalletsColumns = [
    {
      dataField: "wallets",
      text: "Fiat Wallets",
      formatter: (val) => formatDate(val.createdAt)
    },
    {
      dataField: "balance",
      text: "Balance",
      formatter: (item) => (
        captilazeFirstLetter(item.title)
      )
    },
    {
      dataField: "",
      isDummyField: true,
      editable: false,
      text: "Actions",
      formatter: (item) => (
        <Link className="New Payment" to="#">
          <i
            className="mdi mdi-pencil font-size-18"
            id="edittooltip"
            onClick={() => { setAccountType(item); toggleEditModal() }}
          ></i>
        </Link>
      )
    },

  ];
  const dispatch = useDispatch();
  
  const [sizePerPage, setSizePerPage] = useState(10);
  const WalletsOptionGroup = [
    {
      label: "USD", 
      value:"usd"
    },
    {
      label: "ETH", 
      value:"eth"
    },
    {
      label: "BTC", 
      value:"btc"
    },
  ];
  const durationOptionGroup = [
    {
      label: "Last Week", 
      value:"lastWeek"
    },
    {
      label: "Last Month", 
      value:"lastMonth"
    },
    {
      label: "Last 3 Months", 
      value:"last3Months"
    },
  ];
  const timeOptionGroup = [
    {
      label: "Week", 
      value:"week"
    },
    {
      label: "Month", 
      value:"month"
    },
    {
      label: "Year", 
      value:"year"
    },
  ];
  const amountOptionGroup = [
    {
      label: "Amount", 
      value:"amount"
    },
  ];
  useEffect(() => {
    console.log("docs", docs);
  }, [docs]);
  //   useEffect(() => {
  //     loadFeeGroups(1, sizePerPage);
  //   }, [sizePerPage, 1]);
  //   useEffect(()=>{
  //     dispatch(fetchMarketsStart({
  //       limit:1000,
  //       page:1
  //     }));
  //   }, []);
  //  const loadFeeGroups = (page, limit) => {
  //     dispatch(fetchFeeGroupStart({
  //       page,
  //       limit
  //     }));
  //   };
  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Pay Finitic Dashboard
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          {/* <h2>Pay Finitic Dashboard</h2> */}
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex flex-column gap-3">
                  <div className="d-flex justify-content-between  align-items-center">
                    <CardTitle className="color-primary">{("Pay Finitic Dashboard")}</CardTitle>
                    {/* <FeeGroupAdd/> */}
                  </div>
                </CardHeader>
                <Row>
                  <Col lg='6'>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table  table-hover "
                        >
                          <Thead className="text-center table-light" >
                            <Tr>
                              {cryptoWalletsColumns.map((column, index) =>
                                <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                              )}
                            </Tr>
                          </Thead>
                          <Tbody className="text-center">
                            {/* {loading && <TableLoader colSpan={6} />}
                            {!loading && docs.map((row, rowIndex) =>
                              <Tr key={rowIndex}>
                                {cryptoWalletsColumns.map((column, index) =>
                                  <Td key={`${rowIndex}-${index}`}>
                                    {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                  </Td>
                                )}
                              </Tr>
                            )} */}
                            <Tr >
                              <Td colSpan={3}>Comming Soon</Td>
                            </Tr>
                          </Tbody>
                        </Table>
                        <CustomPagination
                          totalPages={totalPages}
                          docs={docs}
                          sizePerPage={sizePerPage}
                          page={page}
                          totalDocs={totalDocs}
                          hasNextPage={hasNextPage}
                          hasPrevPage={hasPrevPage}
                          prevPage={prevPage}
                          nextPage={nextPage}
                          limit={limit}
                          pagingCounter={pagingCounter}
                          setSizePerPage={setSizePerPage}
                          // onChange={loadTeams}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg='6'>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table  table-hover "
                        >
                          <Thead className="text-center table-light" >
                            <Tr>
                              {fiatWalletsColumns.map((column, index) =>
                                <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                              )}
                            </Tr>
                          </Thead>
                          <Tbody className="text-center">
                            {/* {loading && <TableLoader colSpan={6} />}
                            {!loading && docs.map((row, rowIndex) =>
                              <Tr key={rowIndex}>
                                {cryptoWalletsColumns.map((column, index) =>
                                  <Td key={`${rowIndex}-${index}`}>
                                    {column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                  </Td>
                                )}
                              </Tr>
                            )} */}
                            <Tr >
                              <Td colSpan={3}>Comming Soon</Td>
                            </Tr>
                          </Tbody>
                        </Table>
                        <CustomPagination
                          totalPages={totalPages}
                          docs={docs}
                          sizePerPage={sizePerPage}
                          page={page}
                          totalDocs={totalDocs}
                          hasNextPage={hasNextPage}
                          hasPrevPage={hasPrevPage}
                          prevPage={prevPage}
                          nextPage={nextPage}
                          limit={limit}
                          pagingCounter={pagingCounter}
                          setSizePerPage={setSizePerPage}
                          // onChange={loadTeams}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} className="col mb-4 mt-5">
                    <Card className="card-animate">
                      <CardBody>
                        <CardTitle>
                          <h5>{("Conversions")}</h5>
                          <div className='card-selects-wrapper'>
                            <Select
                              name="walletOption" 
                              label="Wallet"
                              options= {WalletsOptionGroup}
                              onChange = {(e)=>setWalletOption(e.value)}
                              className="card-select"
                            />
                            <Select
                              name="durationOption" 
                              label="Duration"
                              options= {durationOptionGroup}
                              onChange = {(e)=>setDurationOption(e.value)}
                              className="card-select"
                            />
                            <Select
                              name="timeOption" 
                              label="Time"
                              options= {timeOptionGroup}
                              onChange = {(e)=>setTimeOption(e.value)}
                              className="card-select"
                            />
                            <Select
                              name="amountOption" 
                              label="Amount"
                              options= {amountOptionGroup}
                              onChange = {(e)=>setAmountOption(e.value)}
                              className="card-select"
                            />
                          </div>
                          {/* <div className="btn-group btn-group-sm mt-2" role="group" aria-label="Basic example">
                            <button type="button" className={"btn btn-info" }  >Week</button>
                            <button type="button" className={"btn btn-info" }  >Month</button>
                            <button type="button" className={"btn btn-primary"} >Year</button>
                          </div> */}
                        </CardTitle>
                        <ReactApexChart
                          options={
                            {
                              chart: {
                                height: 350,
                                type: "line",
                                zoom: {
                                  enabled: false
                                }
                              },
                              dataLabels: {
                                enabled: false
                              },
                              stroke: {
                                curve: "straight"
                              },
                              colors: ["#77B6EA", "#545454"],
                              // title: {
                              //   text: "Product Trends by Month",
                              //   align: "left"
                              // },
                              grid: {
                                row: {
                                  colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                                  opacity: 0.5
                                },
                              },
                              xaxis: {
                                categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
                              }
                            }}
                          series={[
                            {
                              name: "Withdrawals",
                              data: [28, 29, 33, 36, 32, 32, 33]
                            },
                            {
                              name: "Deposits",
                              data: [12, 11, 14, 18, 17, 13, 13]
                            },
                          ]}
                          type="line"
                          height={400}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

    </React.Fragment>
  );
}

export default PayFiniticDashboard;
