import React, { 
  useState, useEffect 
} from "react";
import {
  useDispatch,
  connect,
  useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Label,
  Row,
  Col,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "../SearchableInputStyles.scss";
import { withTranslation } from "react-i18next";
import { addFormDataForexDeposit } from "store/forexTransactions/deposits/actions";
import Loader from "components/Common/Loader";
import Select from "react-select";
function AddFiniticPayFirstPhaseModal(props){
  const dispatch = useDispatch();
  const { create } = props.depositsPermissions;
  const [addModal, setDepositModal] = useState(false);
  // const [memFiles, setMemFiles] = useState({});
  // const [imageError, setImageError] = useState();
  const [gateway, setGateway] = useState("");
  const [currency, setCurrency] = useState("USDT");
  const [network, setNetwork] = useState("TRC20");
  const [amount, setAmount] = useState(null);
  const { userData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const clientFee = userData?.tradingFeeId?.value?.$numberDecimal;
  
  useEffect(() => {
    setGateway("");
    setDepositModal(!addModal);
  }, [props.show]);

  const toggleAddModal = () => {
    // setImageError("");
    setDepositModal(!addModal);
  };

  const handleAddForexDeposit =  (e, v) => {
    e.preventDefault();
    let data = {
      amount: parseFloat(v.amount ?? 0),
      totalPaid: parseFloat(amount) - parseFloat(clientFee),
      currency: v.currency,
      cancelUrl: v.cancelUrl,
      callbackUrl: v.callbackUrl,
      returnUrl: v.returnUrl,
      merchantId: props.customerId
    };
    dispatch(addFormDataForexDeposit(data));
    props.setIsSubmitted(true);
  };

  useEffect(() => {
    if (!props.disableAddButton && open ){
      setDepositModal(false);
    }
  }, [props.modalClear]);
  useEffect(() => {
    if (props.isSubmitted) {
      setDepositModal(props.addForexDepositScondPhaesOpen || false);
    }
  }, [props.addForexDepositScondPhaesOpen]);

  return (
    <React.Fragment >
      <Link to="#" className={"btn btn-primary"} onClick={toggleAddModal}>{props.t("Payment Link")}</Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Create New Checkout Link")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={(e, v) => {
              if (props.customerId) {
                v.customerId = props.customerId;  
              } else {
                v.customerId = props?.account?.length != 0 && props?.accounts?.[0]?.customerId?._id;
                v.tradingAccountId = props?.account?.length != 0 && props?.accounts?.[0]?._id;
                delete v.liveAccount;
                delete v.customerName;
              }
              v.gateway = gateway;
              // v.image = memFiles;
              handleAddForexDeposit(e, v);
            }}
          >

            {/* amount and image */}
            <Row className="mb-3">
              {/* amount */}
              <Col md="12">
                <AvField
                  name="amount"
                  label={props.t("Amount")}
                  placeholder={props.t("Enter Amount")}
                  type="number"
                  min="1"
                  onChange={(e) => setAmount(e.target.value)}
                  errorMessage={props.t("Enter Valid Amount")}
                  validate = {{
                    required :{ value:true }
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              {/* fee */}
              <Col md="12">
                <AvField
                  name="fee"
                  label={props.t("Fee")}
                  value={clientFee}
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="12">
                <Label>{props.t("Currency")}</Label>
                <div>
                  <Select 
                    defaultValue={{
                      label:"USDT",
                      value:"USDT" 
                    }}
                    onChange={(e) => {
                      setCurrency(e.value);   
                    }}
                    options={[{
                      label:"USDT",
                      value:"USDT" 
                    },
                    ]}
                    classNamePrefix="select2-selection"
                    placeholder={props.t("Choose Currency")}
                  />
                  <AvField
                    name="currency"
                    type="text"
                    errorMessage={props.t("Choose Currency")}
                    validate={{ required: { value: true } }}
                    value={currency}
                    style={{
                      opacity: 0,
                      height: 0,
                      width: 0,
                      margin: -10
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md="12">
                <Label>{props.t("Network")}</Label>
                <div>
                  <Select 
                    defaultValue={{
                      label:"TRC20",
                      value:"TRC20" 
                    }}
                    onChange={(e) => {
                      setNetwork(e.value);   
                    }}
                    options={[{
                      label:"TRC20",
                      value:"TRC20" 
                    },
                    ]}
                    classNamePrefix="select2-selection"
                    placeholder={props.t("Choose Network")}
                  />
                  <AvField
                    name="network"
                    type="text"
                    errorMessage={props.t("Choose Network")}
                    validate={{ required: { value: true } }}
                    value={network}
                    style={{
                      opacity: 0,
                      height: 0,
                      width: 0,
                      margin: -10
                    }}
                  />
                </div>
              </Col>
            </Row>
            {amount && amount > 0 && (
              <Alert color="warning" style={{ marginTop: "13px" }}>
                {`Total Paid: ${parseFloat(amount) - parseFloat(clientFee)}`}
              </Alert>
            )}
            {/* <Row className="mb-3">
              <AvField
                name="callbackUrl"
                label={props.t("Callback Url")}
                placeholder={props.t("Enter Callback Url")}
                type="text"
              />
            </Row> */}
    
            {/* <Row className="mb-3">
              <AvField
                name="returnUrl"
                label={props.t("Return Url")}
                placeholder={props.t("Enter Return Url")}
                type="text"
              />
            </Row> */}
    
            {/* <Row className="mb-3">
              <AvField
                name="cancelurl"
                label={props.t("Cancel Url")}
                placeholder={props.t("Enter Cancel Url")}
                type="text"
              />
            </Row> */}
    
            {/* note */}
            {/* <Row className="mb-3">
              <AvField
                name="checkouturl"
                label={props.t("Checkout Url")}
                placeholder={props.t("Enter Checkout Url")}
                type="text"
              />
            </Row> */}
    
            <div className='text-center pt-3 p-2'>
              {
                props.addLoading 
                  ?
                  <Loader />
                  :
                  <Button 
                    disabled = {props.addLoading} 
                    type="submit" 
                    color="primary"
                  >
                    {props.t("Add")}
                  </Button>
              }
            </div>
          </AvForm>
          {props.addForexDepositFailDetails && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2" />
            {props.t(props.addForexDepositFailDetails)}
          </UncontrolledAlert>}
        </ModalBody> 
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  forexDepositsGateways: state.forexGatewayReducer.forexDepositsGateways || [],
  depositsGatewaysLoading: state.forexGatewayReducer.depositsGatewaysLoading || [],
  modalClear: state.forexDepositReducer.modalClear,
  depositsPermissions : state.Profile.depositsPermissions || {}, 
  disableAddButton : state.forexDepositReducer.disableAddButton,
  addLoading: state.forexDepositReducer.addLoading,
  addForexDepositFailDetails: state.forexDepositReducer.addForexDepositFailDetails,
  clientDetails: state.clientReducer.clientDetails,
});
export default connect(mapStateToProps, null)(withTranslation()(AddFiniticPayFirstPhaseModal));