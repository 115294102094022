import React from "react";
import {
  Col,
  Row,
} from "reactstrap";
import { MetaTags } from "react-meta-tags";
import AddressManagementComponent from "./AddressManagementComponent";

function AddressManagement() {

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Address Management
        </title>
      </MetaTags>
      <div className="page-content">
        <div className="container-fluid">
          <h2>Address Management</h2>
          <Row>
            <Col className="col-12">
              <div className="">
                <div className="container-fluid">
                  <AddressManagementComponent/>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default (AddressManagement);