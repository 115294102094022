import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import {
  Modal, Button, ModalHeader, ModalBody, UncontrolledAlert,
  Container,
  Spinner,
  Card,
  Row,
  Col,
} from "reactstrap";
import {
  AvForm, AvField,
} from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import { editUserPass } from "store/users/actions";
import Icofont from "react-icofont";
import { 
  generateQRCodeStart,
  getUserProfile,
  verify2FACodeStart,
} from "store/actions";
import { useSelector } from "react-redux";
import AuthCode from "react-auth-code-input";

function Activate2faModal(props) {
  const { show, t, userData, editSuccess, editError, editResult } = props;
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(show);
  const { settings } = useSelector((state) => ({
    settings: state.Profile.settings,
  }));

  
  //2fa
  const [twoFACode, setTwoFACode] = useState();
  const [showVerification, setShowVerification] = useState(false);
  const { verifyCode, generateQR } = useSelector(
    (state) => state.twoFactorAuthReducer
  );
  const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(false);
  const focusInput2FA = (digits) => {
    const activeInputs = document.querySelectorAll(".twofa-input.active");
    const inputs = document.querySelectorAll(".twofa-input");
    if (activeInputs.length > digits.toString().length)
      activeInputs[activeInputs.length - 1]?.classList.remove("active");
    else inputs[digits.toString().length - 1]?.classList.add("active");
    setTwoFACode(digits);
    if (digits.length === 6) enableDisableTwoFactorAuth(digits);
  };

  useEffect(() => {
    if (generateQR && generateQR.qrCode) {
      setShowVerification(true);
    } else {
      setShowVerification(false);
    }
  }, [generateQR.qrCode]);

  useEffect(() => {
    if (verifyCode?.result?.type === "disable" && verifyCode.success) {
      setTwoFactorAuthEnabled(false);
      dispatch(getUserProfile());

    }
    if (verifyCode?.result?.type === "enable" && verifyCode.success) {
      setTwoFactorAuthEnabled(true);
      dispatch(getUserProfile());
      setModalOpen(false);
    }
  }, [verifyCode]);

  useEffect(() => {
    if (settings.twoFactorAuthEnabled) setTwoFactorAuthEnabled(true);
  }, [settings.twoFactorAuthEnabled]);

  useEffect(() => {
    if (verifyCode.disabled) setShowVerification(false);
  }, [verifyCode.disabled]);


  const enable2FA = () => dispatch(generateQRCodeStart());
  const disable2FA = () => setShowVerification(true);
  const enableDisableTwoFactorAuth = (code) =>{
    twoFactorAuthEnabled
      ? dispatch(
        verify2FACodeStart({
          code: code || twoFACode,
          // token: getToken(),
          type: "disable",
          email: userData.email,
        })
      )
      : dispatch(
        verify2FACodeStart({
          code: code || twoFACode,
          // token: getToken(),
          type: "enable",
          email: userData.email,
        })
      );
  };

  const getCardData = () => {
    let buttonClick = enable2FA;
    let disabled = verifyCode.loading;
    let buttonText = t("Enable");
    let topText = t("Disabled");
    let buttonClass = "btn-success";
    let cdaLoading = generateQR.loading;
    if (twoFactorAuthEnabled) {
      buttonClick = disable2FA;
      disabled = verifyCode.loading;
      buttonText = t("Disable");
      topText = t("Enabled");
      buttonClass = "btn-danger";
      cdaLoading = verifyCode.loading;
    }
    return (
      <>
        <h4>Two Factor Auth ({topText})</h4>
        <Button
          className={buttonClass}
          onClick={buttonClick}
          disabled={disabled}
        >
          {cdaLoading ? <Spinner /> : buttonText}
        </Button>
      </>
    );
  };


  useEffect(() => {
    if (editSuccess) {
      setModalOpen(false);
    }
  }, [editSuccess]);
  
  useEffect(() => {
    setModalOpen(show);
  }, [show]);
  
  const toggle = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <React.Fragment>
      <Modal isOpen={modalOpen} toggle={toggle} centered={true} size="xl">
        <ModalHeader toggle={toggle} tag="h4">
          {t("Activate 2FA")}
        </ModalHeader>
        <ModalBody>
          <div className="card-header">
            <div className="card-title card-title align-items-center flex-column d-flex">{getCardData()}</div>
          </div>
          <Container>
            {generateQR?.qrCode && (
              <Card className="pb-3">
                <Row className="justify-content-center">
                  <Col className="text-center" lg={6}>
                    {generateQR.loading ? (
                      <Spinner></Spinner>
                    ) : (
                      <img
                        src={generateQR.qrCode}
                        alt="qr-code"
                        width={200}
                        height={200}
                      />
                    )}
                    <p>
                      {t(
                        "Use the authencator app on your phone to scan the QR code above."
                      )}
                    </p>
                  </Col>
                </Row>
              </Card>
            )}
            {showVerification && (
              <Card className="py-3 text-center">
                <AuthCode
                  characters={6}
                  allowedCharacters="^[0-9]"
                  containerClassName="twofa-container"
                  inputClassName="twofa-input"
                  onChange={(a) => {
                    focusInput2FA(a);
                  }}
                ></AuthCode>
                <div>
                  <Button
                    color="primary"
                    type="button"
                    className="mt-3 w-md"
                    onClick={enableDisableTwoFactorAuth}
                    disabled={verifyCode.loading}
                  >
                    {t("Verify")}
                  </Button>
                </div>
              </Card>
            )}
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  addLoading: state.usersReducer.addLoading,
  editResult: state.usersReducer.editResult,
  editError: state.usersReducer.editError,
  editSuccess: state.usersReducer.editSuccess,
  editClearingCounter: state.usersReducer.editClearingCounter,
});

export default connect(mapStateToProps, null)(withTranslation()(Activate2faModal));
