export default {
  loadMenus: (
    translate,
    {
      profileMetaInfo: { menu, requests, transactions, settings, riskManagement, payFinitic },
      userPermissions: { get: getUser } = {},
      clientPermissions: { get: getClient, getAssigned: getAssignedClients } = {},
      leadsPermissions: { get: getLeads, getAssigned: getAssignedLeads } = {},
      depositsPermissions: { get: getDeposits, getAssigned: getMyDeposits } = {},
      withdrawalsPermissions: { get: getWithdrawals, getAssigned: getMyWithdrawals } = {},
      internalTransfersPermissions: { get: getInternalTransfers, getAssigned: getMyTransfers } = {},
      requestsPermissions: { get: getRequests, getAssigned: getMyRequests } = {},
      creditPermissions: { get: getCredits, getAssigned: getMyCredits } = {},
      convertPermissions: { get: getConverts } = {},
      markupsPermissions: { get: getMarkups } = {},
      rolesPermissions: { get: getRoles } = {},
      AccTypesPermissions: { get: getAccTypes } = {},
      dictionariesPermissions: { get: getDictionaries } = {},
      finiticPayMerchantPortalPermissions: { get: getfiniticPayMerchantPortal } = {},
      finiticPayDashboardPermissionns: { get: getfiniticPayDashboard } = {},
      feeGroupsPermissions: { get: getFeeGroup } = {},
      systemEmailsPermissions: { get: getSystemEmail } = {},
      emailConfigPermissions: { get: getEmailConfig } = {},
      teamsPermissions: { get: getTeams } = {},
      targetsPermissions: { get: getTargets } = {},
      symbolsPermissions: { get: getSymbols } = {},
      currencyPairsPermissions: { get: getCurrencyPair } = {},
      orderProfitPermissions: { get: getOrderProfit } = {},
      transactionProfitPermissions: { get: getTransactionProfit } = {},
      todosPermissions: { get: getTodos } = {},
      companyBanksPermissions: { get: getCompanyBanks } = {},
      userLogsPermissions: { get: getUserLogs } = {},
      conversionRatePermissions: { get: getConversionRate } = {},
      emailCampaignPermissions: { get: getEmailCampaign } = {},
      addressManagementPermissions: { get: getAddressManagement } = {},
      permissions: {
        reports: {
          get: getReports,
          getAssigned: getAssignedReports,
        } = {}
      } = {},
    }
  ) => [
    {
      menuItemLink: "/pay-finitic-dashboard",
      menuItemName: translate("Dashboard"),
      menuItemIcon: "home",
      className: "",
      visibility: true,
    },
    {
      menuItemLink: "/pay-finitic-dashboard-merchants",
      menuItemName: translate("Merchants"),
      menuItemIcon: "users",
      className: "",
      visibility: true,
    },
    {
      menuItemLink: "/pay-finitic-dashboard-payments",
      menuItemName: translate("Payments"),
      menuItemIcon: "users",
      className: "",
      visibility: true,
    },
    {
      menuItemLink: "/pay-finitic-dashboard-transactions",
      menuItemName: translate("Transactions"),
      menuItemIcon: "monitor",
      className: "",
      visibility: true,
    },
    {
      menuItemLink: "/pay-finitic-dashboard-witdrawals",
      menuItemName: translate("Withdrawals"),
      menuItemIcon: "dollar-sign",
      className: "",
      visibility: true,
    },
    {
      menuItemLink: "/pay-finitic-dashboard-payouts",
      menuItemName: translate("Payouts"),
      menuItemIcon: "book-open",
      className: "",
      visibility: true,
    },
    {
      menuItemLink: "/pay-finitic-dashboard-address-management",
      menuItemName: translate("Address Management"),
      menuItemIcon: "book-open",
      className: "",
      visibility: true,
    },
  ],
};
