import React, {
  useState, useEffect, useCallback
} from "react";
import {
  useDispatch,
  connect,
  useSelector
} from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Col,
  Row,
  Label,
  Card,
  CardTitle,
  CardText,
  Alert
} from "reactstrap";
import * as walletApi from "apis/wallet";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { makeWithdrawalStart } from "store/transactions/withdrawal/action";
import {
  fetchWalletStart, clearWallets
} from "store/wallet/list/action";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import Loader from "components/Common/Loader";
import * as clientApi from "apis/client";
import { getAddresses } from "apis/address";
import { showErrorNotification, toggleCurrentModal } from "store/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import TwoFactorAuthLogin from "pages/Authentication/TwoFactorAuthLogin";

function WithdrawForm(props) {
  const dispatch = useDispatch();
  const [open, setWithdrawalModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedClientWallets, setSelectedClientWallets] = useState([]);
  const [selectedWalletId, setSelectedWalletId] = useState("");
  const [withdrawData, setWithdrawData] = useState({});
  const [walletType, setWalletType] = useState("crypto");
  const [modalType, setModalType] = useState("");
  const [amount, setAmount] = useState(null);
  const { verifyCode, generateQR } = useSelector(
    (state) => state.twoFactorAuthReducer
  );
  const { userData, settings } = useSelector((state) => ({
    userData: state.Profile.userData,
    settings: state.Profile.settings,
  }));
  const { userData : clientData } = useSelector((state) => ({
    userData: state.Profile,
  }));
  const { currentModal, modalData, layoutMode } = useSelector((state) => ({
    currentModal: state.Layout.currentModal,
    modalData: state.Layout.modalData,
    layoutMode: state.Layout.layoutMode,
  }));

  const [fee, setFee] = useState(0);
  const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    docs: [],
  });

  useEffect(() => {
    if (amount > 0) {
      const { isPercentage, minValue: { $numberDecimal: minValue }, onChainfee: { $numberDecimal: onChainfee }, value: { $numberDecimal: value } } = clientData?.tradingFeeId;
      if (isPercentage) {
        const rawFee = parseFloat(amount) * parseFloat(value) / 100;
        const fee = rawFee > parseFloat(minValue) ? rawFee : parseFloat(minValue);
        console.log(`Raw Fee: ${rawFee}, Fee: ${fee}`);
        setFee((fee + parseFloat(onChainfee)).toFixed(2));
      } else {
        const fee = parseFloat(onChainfee) + parseFloat(value);
        setFee(fee);
      }
    } else setFee(0);
  }, [clientData, amount]);

  const handleWithdraw = (event, values) => {
    const selectedWallet = selectedClientWallets.find(wallet => wallet._id === selectedWalletId);
    /*if (selectedWallet.asset === "USDT") {
      const tronWallet = selectedClientWallets.find(wallet => wallet.asset === "TRX");
      if (tronWallet.amount < 60) {
        return dispatch(showErrorNotification("You need to maintain minimum 60 TRX in your wallet to make withdrawal."));
      }
    }*/
    setWithdrawData(values);
    dispatch(toggleCurrentModal("SelectMode"));
  };

  const saveWithdrawal = () => {
    dispatch(makeWithdrawalStart({
      customerId: selectedClient,
      walletId: selectedWalletId,
      walletType,
      // gateway,
      ...withdrawData,
      network: withdrawData?.chainId?.shortName ?? "TRC20",
    }));
    setSearchInput("");
    dispatch(clearWallets());
    //dispatch(toggleCurrentModal(""));
  };

  useEffect(async () => {

    if (selectedClient) {
      let payload = {
        belongsTo: selectedClient
      };
      const wallets = await walletApi.getClientWalletDetails({ payload });
      setSelectedClientWallets(wallets?.result);
    }
  }, [selectedClient]);
  useEffect(() => {
    setSelectedClient(props.customerId);
  }, [props.customerId]);


  const toggleAddModal = () => {
    if (currentModal === "EmailPin") {
      dispatch(toggleCurrentModal("SelectMode"));
    }
    else if (currentModal === "SelectMode") {
      dispatch(toggleCurrentModal(""));
    }
    else if (currentModal === "MakeWithdraw") {
      dispatch(toggleCurrentModal(""));
    }
    else {
      dispatch(toggleCurrentModal("MakeWithdraw"));
    }
    // if (settings.twoFactorAuthEnabled) {
    // } 
    // setWithdrawalModal(!open);
  };

  const loadAddresses = async (page = 1, limit = 10) => {
    setLoading(true);
    getAddresses({
      page,
      limit,
    }).then((response) => {
      if (response.isSuccess) {
        const { docs, ...rest } = response.result;
        console.log(response);
        setPayload({
          docs,
          pagination: rest,
        });
      }
    }).catch((error) => {
      console.log(error);
      setPayload({
        docs: [],
        pagination: {},
      });
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    loadAddresses(1, 1000);
  }, []);

  useEffect(() => {
    if (props.withdrawalModalClear && open) {
      setWithdrawalModal(false);
    }
  }, [props.withdrawalModalClear]);


  // debounce function handlers
  const fetchClientWalletDebounceHandler = useCallback(
    debounce((id) => setSelectedWalletId(id), 1000), []
  );
  useEffect(() => {
    if (verifyCode?.result?.type === "disable" && verifyCode.success) {
      setTwoFactorAuthEnabled(false);
    }
    if (verifyCode?.result?.type === "enable" && verifyCode.success) {
      setTwoFactorAuthEnabled(true);
    }
  }, [verifyCode]);

  useEffect(() => {
    if (settings.twoFactorAuthEnabled) setTwoFactorAuthEnabled(true);
  }, [settings.twoFactorAuthEnabled]);

  return (
    <React.Fragment >
      <Link to="#" className={"btn btn-primary"} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i> {props.t("Add New Payout")}</Link>
      {currentModal === "EmailPin" &&
        <TwoFactorAuthLogin
          isOpen={currentModal === "EmailPin"}
          email={userData?.email}
          type={"withdraw"}
          history={props.history}
          modalType={modalType}
          toggleOpen={() => {
            dispatch(toggleCurrentModal(""));
          }}
          saveWithdrawal={() => saveWithdrawal()}>
        </TwoFactorAuthLogin>
      }
   
      <Modal isOpen={currentModal === "SelectMode"} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Select Your Authentication Method")}
        </ModalHeader>
        <ModalBody>        
          <Row>
            <Col sm="6">
              <Card body>
                <CardTitle tag="h5">
                  Email Authentication
                </CardTitle>
                <CardText>
                  Receive the authentication code in your inbox.
                </CardText>
                <Button onClick={() => {
                  setModalType("email");
                  dispatch(toggleCurrentModal("EmailPin"));
                }}>
                  Go 
                </Button>
              </Card>
            </Col>
            {<Col sm="6">
              <Card body>
                <CardTitle tag="h5">
                  2FA Authentication
                </CardTitle>
                <CardText>
                  Get the auth code from Windows Authenticator app.
                </CardText>
                <Button onClick={() => {
                  setModalType("2fa");
                  dispatch(toggleCurrentModal("EmailPin"));
                }} disabled = {!settings.twoFactorAuthEnabled}>
                  Go 
                </Button>
              </Card>
            </Col>
            }
          </Row>
        </ModalBody>
      </Modal>
    
      <Modal isOpen={currentModal === "MakeWithdraw"} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Make Withdraw")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='px-4 py-2'
            onValidSubmit={(e, v) => {
              delete v.client;
              delete v.wallet;
              delete v.gateway;
              delete v.type;
              delete v.fee;
              handleWithdraw(e, v);
            }}
          >
            <Row className="mb-3">
              <Col md="12">
                <Label>{props.t("Type")}</Label>
                <div>
                  <Select
                    defaultValue={{
                      label: "Crypto",
                      value: "crypto"
                    }}
                    onChange={(e) => {
                      setWalletType(e.value);
                    }}
                    options={[{
                      label: "Crypto",
                      value: "crypto"
                    },
                    {
                      label: "Fiat",
                      value: "fiat",
                      isDisabled: true
                    }]}
                    classNamePrefix="select2-selection"
                    placeholder={props.t("Choose Withdrawal Type")}
                  />
                  <AvField
                    name="type"
                    type="text"
                    errorMessage={props.t("Choose Withdrawal Type")}
                    validate={{ required: { value: true } }}
                    value={walletType}
                    style={{
                      opacity: 0,
                      height: 0,
                      width: 0,
                      margin: -10
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md="12">
                <Label>{props.t("Wallet")}</Label>
                <div>
                  <Select
                    onChange={(e) => {
                      fetchClientWalletDebounceHandler(e.value.id);
                    }}
                    isSearchable={true}
                    options={selectedClientWallets?.map((wallet) => (
                      {
                        label: `${wallet.asset}-(Balance ${wallet.amount} ${wallet.asset})`,
                        value: {
                          id: `${wallet._id}`
                        }
                      }
                    ))}
                    classNamePrefix="select2-selection"
                    placeholder={props.t("Choose A Wallet")}
                  />
                  <AvField
                    name="wallet"
                    type="text"
                    errorMessage={props.t("Choose A Wallet")}
                    validate={{ required: { value: true } }}
                    value={selectedWalletId}
                    style={{
                      opacity: 0,
                      height: 0,
                      width: 0,
                      margin: -10
                    }}
                  />
                </div>
              </Col>
            </Row>

            <div className="mb-3">
              <AvField
                name="amount"
                label={props.t("Amount")}
                placeholder={props.t("Enter An Amount")}
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                errorMessage={props.t("Enter An Amount")}
                validate={{
                  required: { value: true },
                  min: {
                    value: clientData?.tradingFeeId?.minWithdrawal?.$numberDecimal ?? 50,
                    errorMessage: `${props.t("Minimum is")} 50`
                  }
                }}
              />
            </div>

            <div className="mb-3">
              <AvField
                name="fee"
                label={props.t("Fee (Platform Fee + On-Chain Fee)")}
                value={fee}
                disabled
              />
            </div>

            <div className="mb-3">
              <AvField
                name="address"
                label={props.t("Address")}
                placeholder={props.t("Enter Address")}
                type="text"
                validate={{
                  required: { value: true },
                }}
              />
            </div>

            <div className="mb-3">
              <AvFieldSelect
                name="network"
                label={props.t("Network")}
                placeholder={props.t("Enter Network")}
                options={payload.docs?.map((gateway) => ({
                  label: `${gateway.chainId?.shortName}`,
                  value: gateway
                }))}
                type="text"
              />
            </div>
            <div className="mb-3">
              <AvField
                name="note"
                label={props.t("Note")}
                placeholder={props.t("Enter Note")}
                type="text"
              />
            </div>
            {amount && amount > 0 && (
              <Alert color="warning" style={{ marginTop: "13px" }}>
                {`Total Payable: ${parseFloat(amount) - parseFloat(fee)}`}
              </Alert>
            )}

            <div className='text-center mt-3 p-2'>
              {
                props.addLoading
                  ?
                  <Loader />
                  :
                  <Button
                    disabled={props.addLoading}
                    type="submit"
                    color="primary"
                  >
                    {props.t("Add")}
                  </Button>
              }
            </div>
          </AvForm>

          {props.error && <UncontrolledAlert color="danger">
            <i className="mdi mdi-block-helper me-2"></i>
            {props.t(props.addWithdrawalErrorDetails)}
          </UncontrolledAlert>}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  gateways: state.gatewayReducer.gateways || [],
  forexWithdrawalsGateways: state.forexGatewayReducer.forexWithdrawalsGateways || [],
  userData: state.Profile.userData || {},
  error: state.withdrawalReducer.addWithdrawalError,
  addWithdrawalErrorDetails: state.withdrawalReducer.addWithdrawalErrorDetails,
  withdrawResponseMessage: state.withdrawalReducer.withdrawResponseMessage,
  withdrawalModalClear: state.withdrawalReducer.withdrawalModalClear,
  clients: state.clientReducer.clients || [],
  wallets: state.walletReducer.wallet.docs || [],
  walletsss: state.walletReducer || [],
  withdrawalsPermissions: state.Profile.withdrawalsPermissions || {},
  disableWithdrawalButton: state.withdrawalReducer.disableWithdrawalButton,
  totalDocs: state.clientReducer.totalDocs,
  loading: state.clientReducer.loading,
  addLoading: state.withdrawalReducer.addLoading
});
export default connect(mapStateToProps, null)(withTranslation()(WithdrawForm));