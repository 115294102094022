export const GENERATE_QR_CODE_START = "GENERATE_QR_CODE_START";
export const GENERATE_QR_CODE_SUCCCESS = "GENERATE_QR_CODE_SUCCCESS";
export const GENERATE_QR_CODE_ERROR = "GENERATE_QR_CODE_ERROR";
export const VERIFY_TWO_FACTOR_CODE_START = "VERIFY_TWO_FACTOR_CODE_START";
export const VERIFY_TWO_FACTOR_CODE_SUCCESS = "VERIFY_TWO_FACTOR_CODE_SUCCESS";
export const VERIFY_TWO_FACTOR_CODE_FAIL = "VERIFY_TWO_FACTOR_CODE_FAIL";
export const GENERATE_EMAIL_CODE_START = "GENERATE_EMAIL_CODE_START";
export const GENERATE_EMAIL_CODE_SUCCCESS = "GENERATE_EMAIL_CODE_SUCCCESS";
export const GENERATE_EMAIL_CODE_ERROR = "GENERATE_EMAIL_CODE_ERROR";
export const VERIFY_EMAIL_CODE_START = "VERIFY_EMAIL_CODE_START";
export const VERIFY_EMAIL_CODE_SUCCESS = "VERIFY_EMAIL_CODE_SUCCESS";
export const VERIFY_EMAIL_CODE_FAIL = "VERIFY_EMAIL_CODE_FAIL";