import React, { 
  useState, useEffect, useCallback 
} from "react";
import { useDispatch, connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
  Label,
  Row,
  Col,
  Input
} from "reactstrap";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "../SearchableInputStyles.scss";
import { withTranslation } from "react-i18next";
import { addForexDeposit, addFormDataForexDeposit } from "store/forexTransactions/deposits/actions";
import Loader from "components/Common/Loader";
import { fetchTradingAccountByLogin } from "store/tradingAccounts/actions";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { fetchForexDepositsGatewaysStart } from "store/forexGateway/actions";
import CustomSelect from "components/Common/CustomSelect";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { validateFile } from "pages/Transactions/Crypto/AddDepositForm";
import Select from "react-select";
import { showSuccessNotification } from "store/actions";
function AddFiniticPaySecondPhaseModal(props){
  const dispatch = useDispatch();
  const { create } = props.depositsPermissions;
  const [addModal, setDepositModal] = useState(true);
  const toggleAddModal = () => {
    setDepositModal(!addModal);
  };

  const copyToClipboard = ()=> {
    navigator.clipboard.writeText(props.url);
    dispatch(showSuccessNotification("Link Copied"));
  };
  
  useEffect(() => {
    return () => {
      props.setIsSubmitted(false);
    };
  }, [addModal]);

  return (
    <React.Fragment >
      {/* <Link to="#" className={"btn btn-primary"} onClick={toggleAddModal}><i className="bx bx-plus me-1"></i> {props.t("Create New Checkout")}</Link> */}
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {props.t("Order Created")}
        </ModalHeader>
        <ModalBody >
          <h3 className="text-center">Your order has been created </h3>
          <h4 className="text-center">Link to your Order</h4>
          <p onClick={copyToClipboard} className="text-center">{props.url}</p>
        </ModalBody> 
      </Modal>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => ({
  forexDepositsGateways: state.forexGatewayReducer.forexDepositsGateways || [],
  depositsGatewaysLoading: state.forexGatewayReducer.depositsGatewaysLoading || [],
  addForexDepositScondPhaesOpen: state.forexDepositReducer.addForexDepositScondPhaesOpen || false,
  modalClear: state.forexDepositReducer.modalClear,
  depositsPermissions : state.Profile.depositsPermissions || {}, 
  disableAddButton : state.forexDepositReducer.disableAddButton,
  addLoading: state.forexDepositReducer.addLoading,
  addForexDepositFailDetails: state.forexDepositReducer.addForexDepositFailDetails,
  tradingAccounts: state.tradingAccountReducer.tradingAccounts,
  accounts: state.tradingAccountReducer.loginTradingAccounts,
  quickActionAccs: state.tradingAccountReducer.accounts.docs,
  customerTradingAccounts: state.tradingAccountReducer.customerTradingAccounts,
  fetchTradingAccountsFail: state.tradingAccountReducer.fetchTradingAccountsFail,
  fetchTradingAccountsByLoginLoading: state.tradingAccountReducer.fetchTradingAccountsByLoginLoading,
  fetchTradingAccountsByLoginSuccess: state.tradingAccountReducer.fetchTradingAccountsByLoginSuccess,
  fetchTradingAccountsByLoginFail: state.tradingAccountReducer.fetchTradingAccountsByLoginFail,
  fetchTradingAccountsByLoginFailDetails: state.tradingAccountReducer.fetchTradingAccountsByLoginFailDetails
});
export default connect(mapStateToProps, null)(withTranslation()(AddFiniticPaySecondPhaseModal));