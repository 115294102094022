import React, {
  useEffect,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import "../SearchableInputStyles.scss";
import { showSuccessNotification } from "store/actions";
import { useTranslation } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import AvFieldSelect from "components/Common/AvFieldSelect";
import { getAddresses } from "apis/address";
import QRCode from "qrcode.react";
import FeatherIcon from "feather-icons-react";
import { cpUrl } from "content";
import Select from "react-select";

function MerchantDepositAddressModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [addModal, setDepositModal] = useState(false);
  const [puk, setPuk] = useState("");
  const [showAddress, setShowAddress] = useState(true);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    docs: [],
    pagination: {},
  });
  //const { userData = {} } = useSelector(state => state.Profile);
  const [currency, setCurrency] = useState("");
  //const { userData = {}  } = useSelector(state => state.Profile);

  const pukHandeler = (puk) => {
    setPuk(puk);
    // console.log(puk);
  };

  const currencyHandeler = (currency) => {
    setCurrency(currency);
  };


  const toggleAddModal = () => {
    setDepositModal(!addModal);
  };

  const toggleShowAddress = () => {
    setShowAddress(!showAddress);
    setPuk("");
  };

  const loadAddresses = async (page = 1, limit = 10) => {
    setLoading(true);
    getAddresses({
      page,
      limit,
    }).then((response) => {
      if (response.isSuccess) {
        const { docs, ...rest } = response.result;
        console.log(docs);
        setPayload({
          docs,
          pagination: rest,
        });
      }
    }).catch((error) => {
      console.log(error);
      setPayload({
        docs: [],
        pagination: {},
      });
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    loadAddresses(1, 1000);
  }, []);

  useEffect(() => {
    return () => {
      setPuk("");
    };
  }, [addModal]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(puk);
    dispatch(showSuccessNotification("Address Copied"));
  };

  const copyToClipboardLink = () => {
    //navigator.clipboard.writeText(`${cpUrl}/api/v1/cp/psp/finitic-pay/merchant/${userData._id}`);
    dispatch(showSuccessNotification("Link Copied"));
    toggleAddModal();
  };
  
  return (
    <React.Fragment >
      <Link to="#" className={"btn btn-primary"} onClick={toggleAddModal}>{t("View My Address")}</Link>
      <Modal isOpen={addModal} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          {t("Your Deposit Address")}
        </ModalHeader>
        <ModalBody >
          {/* option to either get a link or puk */}
          {
            !showAddress && (
              <div
                className="d-flex flex-column justify-content-center"
              >
                {
                  props.hasDepositAddress ?
                    <>
                      <Button color="primary" onClick={toggleShowAddress}>{t("View My Address")}</Button>

                      <div className="d-flex">
                        <hr
                          style={{
                            width: "100%",
                            height: "1px",
                            backgroundColor: "gray",
                          }}
                        />
                        <span className="mx-2 align-self-center font-weight-bold">OR</span>
                        <hr style={{
                          width: "100%",
                          height: "1px",
                          backgroundColor: "gray",
                        }} />
                      </div>
                    </> : null

                }
                <Button color="primary" onClick={() => props.setOwnDepositAddress(false)}>{t("Payment Link")}</Button>
              </div>
            )
          }
          { (
            <>
              <AvForm className="my-2">
                <AvFieldSelect
                  name="currency"
                  label={t("Currency")}
                  onChange={currencyHandeler}
                  options={payload?.docs?.map((puk) => ({
                    label: `${puk?.tokens?.[0]}`,
                    value: `${puk?.tokens?.[0]}`,
                  }))}
                />
                <AvFieldSelect
                  name="networks"
                  label={t("Networks")}
                  onChange={pukHandeler}
                  options={payload?.docs?.filter(p => p.tokens?.[0] == currency).map((puk) => ({
                    label: `${puk?.chainId?.shortName}`,
                    value: puk?.address,
                  }))}
                />
                {puk && <div className="mt-3 d-flex justify-content-center">
                  <QRCode size={300} value={puk} renderAs="canvas" />
                </div>}
                {puk && <>
                  <div  className="d-flex justify-content-center my-2 gap-2">
                    {puk}
                    {puk && (<>
                      <Link to="#" onClick={copyToClipboard}>
                        <FeatherIcon icon="copy" />
                      </Link>
                    </>)}
                    <br/>
                  </div>
                </>}
              </AvForm>
              {/* <div className="d-flex justify-content-center">
                <Button color="primary" onClick={toggleShowAddress}>{t("Back")}</Button>
              </div> */}
            </>
          )}
        </ModalBody> 
      </Modal>
    </React.Fragment>
  );
}
export default (MerchantDepositAddressModal);