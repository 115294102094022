import React, {
  useEffect,
  useState
} from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import AuthCode from "react-auth-code-input";
import { useDispatch, useSelector } from "react-redux";
import {
  generateEmailCodeStart,
  toggleCurrentModal,
  verify2FACodeStart,
  verifyEmailCodeStart,
} from "store/actions";
import { ModalTitle } from "react-bootstrap";

function TwoFactorAuth(props) {
  const [twoFACode, setTwoFACode] = useState();
  const [is2faSuccess, setIs2faSuccess] = useState(false);
  const { verifyCode } = useSelector(state => state.twoFactorAuthReducer);
  const { settings } = useSelector((state) => ({
    settings: state.Profile.settings,
  }));
  const dispatch = useDispatch();
  const focusInput2FA = (digits) => {
    const activeInputs = document.querySelectorAll(".twofa-input.active");
    const inputs = document.querySelectorAll(".twofa-input");
    if (activeInputs.length > digits.toString().length)
      activeInputs[activeInputs.length - 1]?.classList.remove("active");
    else
      inputs[digits.toString().length - 1]?.classList.add("active");
    setTwoFACode(digits);
    const digitLength = props.modalType === "email" ? 4 : 6;
    if (digits.length === digitLength) verify(digits);
  };
  const verify = async (code) => {
    if (props.modalType === "email") {
      dispatch(verifyEmailCodeStart({
        emailPin: code || twoFACode,
        email: props.email && props.email.toLowerCase(),
        history: props.history,
        type: props.type,
        user2fa: settings.twoFactorAuthEnabled,
      }));
    } else {
      dispatch(verify2FACodeStart({
        code: code || twoFACode,
        email: props.email && props.email.toLowerCase(),
        history: props.history,
        type: props.type,
      }));
    }
  };

  useEffect(() => {
    if (verifyCode.error && verifyCode.error.length > 0)
      setTwoFACode("");
    if (verifyCode.success) {
      console.log(verifyCode);
      setIs2faSuccess(true);
      // props.saveWithdrawal();
    }
  }, [verifyCode.error, verifyCode.success]);

  useEffect(() => {
    if (is2faSuccess) {
      props?.saveWithdrawal && props?.saveWithdrawal();
    }
  }, [is2faSuccess]);

  useEffect(()=> {
    setIs2faSuccess(false);
  }, [props.modalType]);


  useEffect(() => {
    if (props.modalType === "email") {
      dispatch(generateEmailCodeStart({
        // code: code || twoFACode,
        email: props.email && props.email.toLowerCase(),
        history: props.history,
        type: props.type,
      }));
    }
    setIs2faSuccess(false);
    
  }, [props.modalType]);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggleOpen}
      centered={true}
      size="lg"
    >
      <ModalHeader toggle={() => {
        dispatch(toggleCurrentModal("SelectMode"));
      }}>
        <ModalTitle>
          {props.modalType === "email" ? props.t("Email Code") : props.t("Two Factor Authentication Code")}
        </ModalTitle>
      </ModalHeader>
      <ModalBody className="text-center my-2">
        <AuthCode
          characters={props.modalType === "email" ? 4 : 6}
          allowedCharacters="^[0-9]"
          containerClassName="twofa-container"
          inputClassName="twofa-input"
          onChange={(a) => { focusInput2FA(a) }}></AuthCode>
        <Button color="primary" disabled={verifyCode.loading} className="mt-4 w-md" onClick={() => {
          verify();
        }}>{props.t("Verify")}</Button>
      </ModalBody>
    </Modal>
  );
}
export default withTranslation()(TwoFactorAuth);