import React from "react";
import { ReactComponent as CopyIcon } from "assets/images/copy-icon.svg";
import { showSuccessNotification } from "store/actions";
import { useDispatch } from "react-redux";

export default function MinifiText({ text, link }) {
  const dispatch = useDispatch();
  function copyToClipboard() {
    navigator.clipboard.writeText(text);
    dispatch(showSuccessNotification("Text copied successfully!"));
  }
  function limitText(value) {
    return value?.length > 5 ? `${value?.slice(0, 4)}...${value?.slice(value?.length - 3, value?.length)}` : value;
  }
  return (
    <div className="d-flex align-items-center gap-1">
      {
        link ?
          <a href={link}
            target="_blank" rel="noreferrer"
          >{limitText(text)}</a>
          :
          limitText(text)
      }
      {
        text && 
          <button type="button" className="btn btn-transparent p-0" onClick={()=> copyToClipboard()}>
            <CopyIcon/>
          </button>
      }
    </div>
  );
}