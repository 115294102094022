import * as axiosHelper from "./api_helper";
import qs from "qs";

// fetch forex withdrawals
export const getForexWithdrawals = async ({ payload }) => {
  const result = await axiosHelper.get(`/fxtransactions/withdrawals?${qs.stringify(payload)}`);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};
export const getWithdrawals = async ({ payload })=>{
  const withdrawals = await axiosHelper.get(`/transactions/withdraw?${qs.stringify(payload)}`);
  return withdrawals;
};

export const approveWithdrawal = async (id, customerId)=>{
  const result = await axiosHelper.patch(`/transactions/withdraw/${id}/approve`, { customerId });
  if (result.isError){
    throw new Error(result.message);
  }
  
  return result;
};
export const rejectWithdrawal = async (id, customerId)=>{
  const result = await axiosHelper.patch(`/transactions/withdraw/${id}/reject`, { customerId });
  if (result.isError){
    throw new Error(result.message);
  }
  
  return result;
};
// add forex withdrawal
export const postForexWithdrawal = async ({ payload }) => {
  const result = await axiosHelper.post("/fxtransactions/withdrawals", payload);
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const approveForexWithdrawal = async (id, customerId) => {
  const result = await axiosHelper.patch(`/fxtransactions/withdraw/${id}/approve`, { customerId:customerId });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};

export const rejectForexWithdrawal = async (id, customerId)=>{
  const result = await axiosHelper.patch(`/fxtransactions/withdraw/${id}/reject`, { customerId:customerId });
  if (!result.status){
    throw new Error(result.message);
  }

  return result;
};